import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { formatDistanceToNowStrict } from 'date-fns'

import { Button } from '../../../components/Button'
import { Copyable } from '../../../components/Copyable'
import { Warning } from '../../../components/Modals/Warning'
import {
  ButtonsContainer,
  ModalFooter,
  WithdrawModal,
} from '../../../components/Modals/WithdrawModal'
import { Text } from '../../../components/Text'
import TokenBalance from '../../../components/TokenBalance'
import { useWithdrawEpochs } from '../../../hooks/useWithdrawEpochs'
import { getShortAddress } from '../../../utils/getShortAddress'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from '../styled'

export function WithdrawFromFailedCC({
  capacityCommitment,
  onWithdraw,
  onClose,
}: {
  onClose: () => void
  capacityCommitment: CapacityCommitmentDashboard
  onWithdraw: () => void
}) {
  const availableToWithdrawDate = useWithdrawEpochs(capacityCommitment)

  const isAvailableToWithdraw =
    availableToWithdrawDate && availableToWithdrawDate.end - Date.now() <= 0

  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text size={20} color="red">
              Failed
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      contentMinHeight={
        availableToWithdrawDate && availableToWithdrawDate.period > 0
          ? '150px'
          : '50px'
      }
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
      warning={
        availableToWithdrawDate &&
        availableToWithdrawDate.period > 0 && (
          <Warning>
            Because you are withdrawing collateral from a FAILED commitment, the
            withdrawal period is{' '}
            {formatDistanceToNowStrict(
              Date.now() + availableToWithdrawDate.period,
            )}
            .
          </Warning>
        )
      }
      footer={({ handleClose, handleSubmit }) => (
        <ModalFooter>
          {isAvailableToWithdraw ? (
            <ButtonsContainer>
              <Button variant="grey" isLarge onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="black" isLarge onClick={handleSubmit}>
                Withdraw
              </Button>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <Button variant="dash" isLarge>
                Wait end of cooldown period
              </Button>
            </ButtonsContainer>
          )}
        </ModalFooter>
      )}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            Collateral to withdraw
          </Text>
          <TokenBalance balance={capacityCommitment.currentCollateral} />
        </RowWithdrawTotal>
        {!isAvailableToWithdraw && availableToWithdrawDate && (
          <RowWithdrawTotal>
            <Text size={12} color="grey500" weight={500}>
              End cooldown
            </Text>
            <Text size={18}>
              ~ {formatDistanceToNowStrict(availableToWithdrawDate.end)}
            </Text>
          </RowWithdrawTotal>
        )}
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
