import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'

import { Copyable } from '../../../components/Copyable'
import { WithdrawModal } from '../../../components/Modals/WithdrawModal'
import { Text } from '../../../components/Text'
import TokenBalance from '../../../components/TokenBalance'
import { getShortAddress } from '../../../utils/getShortAddress'

import {
  RowWithdrawTotal,
  RowWithdrawTotalWrapper,
  WithdrawModalTitle,
} from '../styled'

export function WithdrawFromCompletedCC({
  capacityCommitment,
  onClose,
  onWithdraw,
}: {
  onClose: () => void
  onWithdraw: () => void
  capacityCommitment: CapacityCommitmentDashboard
}) {
  return (
    <WithdrawModal
      title={
        <WithdrawModalTitle>
          <Text size={20} color="grey500">
            You are about to withdraw collateral from capacity commitment
            with&nbsp;
            <Text
              size={20}
              color={capacityCommitment.status === 'failed' ? 'red' : 'green'}
            >
              {capacityCommitment.status === 'failed' ? 'Failed' : 'Finished'}
            </Text>
            &nbsp;status
          </Text>
          <Copyable value={capacityCommitment.id}>
            <Text size={12} color="grey500">
              ID: {getShortAddress(capacityCommitment.id, 24)}
            </Text>
          </Copyable>
        </WithdrawModalTitle>
      }
      contentMinHeight="100px"
      isVisible
      onClose={onClose}
      onSubmit={onWithdraw}
    >
      <RowWithdrawTotalWrapper>
        <RowWithdrawTotal>
          <Text size={12} color="grey500" weight={500}>
            Collateral to withdraw
          </Text>
          <TokenBalance balance={capacityCommitment.currentCollateral} />
        </RowWithdrawTotal>
      </RowWithdrawTotalWrapper>
    </WithdrawModal>
  )
}
