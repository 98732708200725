import React from 'react'
import ReactDOM from 'react-dom/client'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { CacheProvider } from '@rest-hooks/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'

import { ClientProvider } from './components/ClientProvider'
import { CustomAvatar } from './components/CustomAvatar'
import SignerProvider from './components/SignerProvider'

import { CHAIN, WAGMI_CONFIG } from './constants/config'
import { App } from './App'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CacheProvider>
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={WAGMI_CONFIG}>
          <RainbowKitProvider initialChain={CHAIN} avatar={CustomAvatar}>
            <ClientProvider>
              <SignerProvider>
                <App />
              </SignerProvider>
            </ClientProvider>
          </RainbowKitProvider>
        </WagmiProvider>
      </QueryClientProvider>
    </CacheProvider>
  </React.StrictMode>,
)
