import React from 'react'
import { CapacityCommitmentStatus } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'

import { STATUS_COLORS, STATUS_NAMES } from '../constants/statusesMap'

import { Status } from './Status'
import { UppercaseText } from './Text'

interface CapacityStatusProps {
  status: CapacityCommitmentStatus
  withdraw?: boolean
}

export const CapacityStatus: React.FC<CapacityStatusProps> = ({
  status,
  withdraw,
}) => {
  const record = withdraw ? 'withdrawn' : status

  const title = STATUS_NAMES[record]
  const color = STATUS_COLORS[record]

  return (
    <Status color={color}>
      <UppercaseText color="white" weight={800} size={10}>
        {title}
      </UppercaseText>
    </Status>
  )
}
