export const chains: {
  [id: string]: string
} = {
  '1': 'Ethereum',
  '5': 'Goerli',
  '7': 'ThaiChain',
  '10': 'OP Mainnet',
  '14': 'Flare Mainnet',
  '16': 'Coston',
  '19': 'Songbird Mainnet',
  '25': 'Cronos Mainnet',
  '30': 'Rootstock Mainnet',
  '31': 'Rootstock Testnet',
  '40': 'Telos',
  '41': 'Telos',
  '42': 'LUKSO',
  '44': 'Crab Network',
  '46': 'Darwinia Network',
  '50': 'XinFin Network',
  '51': 'Apothem Network',
  '56': 'BNB Smart Chain',
  '57': 'Syscoin Mainnet',
  '61': 'Ethereum Classic',
  '66': 'OKC',
  '71': 'Conflux eSpace Testnet',
  '82': 'Meter',
  '83': 'Meter Testnet',
  '96': 'Bitkub',
  '97': 'Binance Smart Chain Testnet',
  '100': 'Gnosis',
  '109': 'Shibarium',
  '114': 'Coston2',
  '122': 'Fuse',
  '123': 'Fuse Sparknet',
  '137': 'Polygon',
  '148': 'Shimmer',
  '153': 'Redbelly Network Testnet',
  '157': 'Puppynet Shibarium',
  '169': 'Manta Pacific Mainnet',
  '195': 'X1 Testnet',
  '196': 'X Layer Mainnet',
  '199': 'BitTorrent',
  '202': 'Edgeless Testnet',
  '204': 'opBNB',
  '240': 'Nexilix Smart Chain',
  '242': 'Plinga',
  '248': 'Oasys',
  '250': 'Fantom',
  '252': 'Fraxtal',
  '255': 'Kroma',
  '260': 'ZKsync InMemory Node',
  '270': 'ZKsync CLI Local Node',
  '282': 'Cronos zkEVM Testnet',
  '288': 'Boba Network',
  '295': 'Hedera Mainnet',
  '296': 'Hedera Testnet',
  '297': 'Hedera Previewnet',
  '300': 'ZKsync Sepolia Testnet',
  '314': 'Filecoin Mainnet',
  '321': 'KCC Mainnet',
  '324': 'ZKsync Era',
  '338': 'Cronos Testnet',
  '369': 'PulseChain',
  '420': 'Optimism Goerli',
  '424': 'PGN',
  '462': 'Areon Network Testnet',
  '463': 'Areon Network',
  '545': 'FlowEVM Testnet',
  '570': 'Rollux Mainnet',
  '571': 'MetaChain Mainnet',
  '592': 'Astar',
  '595': 'Mandala TC9',
  '599': 'Metis Goerli',
  '646': 'FlowEVM Previewnet',
  '686': 'Karura',
  '690': 'Redstone',
  '701': 'Koi Network',
  '721': 'Lycan',
  '747': 'FlowEVM Mainnet',
  '787': 'Acala',
  '841': 'Taraxa Mainnet',
  '842': 'Taraxa Testnet',
  '888': 'Wanchain',
  '919': 'Mode Testnet',
  '943': 'PulseChain V4',
  '957': 'Lyra Chain',
  '997': '5ireChain Thunder Testnet',
  '999': 'Zora Goerli Testnet',
  '1001': 'Klaytn Baobab Testnet',
  '1004': 'Ekta Testnet',
  '1017': 'BNB Greenfield Chain',
  '1028': 'BitTorrent Chain Testnet',
  '1030': 'Conflux eSpace',
  '1038': 'Bronos Testnet',
  '1039': 'Bronos',
  '1073': 'Shimmer Testnet',
  '1088': 'Metis',
  '1101': 'Polygon zkEVM',
  '1111': 'WEMIX',
  '1112': 'WEMIX Testnet',
  '1116': 'Core Dao',
  '1130': 'DeFiChain EVM Mainnet',
  '1131': 'DeFiChain EVM Testnet',
  '1135': 'Lisk',
  '1281': 'Moonbeam Development Node',
  '1284': 'Moonbeam',
  '1285': 'Moonriver',
  '1287': 'Moonbase Alpha',
  '1328': 'Sei Testnet',
  '1329': 'Sei Network',
  '1337': 'Localhost',
  '1442': 'Polygon zkEVM Testnet',
  '1453': 'MetaChain Istanbul',
  '1559': 'Tenet',
  '1625': 'Gravity Alpha Mainnet',
  '1663': 'Horizen Gobi Testnet',
  '1686': 'Mint Sepolia Testnet',
  '1729': 'Reya Network',
  '1750': 'Metal L2',
  '1890': 'LightLink Phoenix Mainnet',
  '1891': 'LightLink Pegasus Testnet',
  '1993': 'B3 Sepolia',
  '1994': 'Ekta',
  '2000': 'Dogechain',
  '2017': 'Telcoin Adiri Testnet',
  '2020': 'Ronin',
  '2021': 'Saigon Testnet',
  '2022': 'Beresheet BereEVM Testnet',
  '2026': 'Edgeless Network',
  '2221': 'Kava EVM Testnet',
  '2222': 'Kava EVM',
  '2331': 'RSS3 VSL Sepolia Testnet',
  '2358': 'Kroma Sepolia',
  '2442': 'Polygon zkEVM Cardona',
  '2522': 'Fraxtal Testnet',
  '2525': 'inEVM Mainnet',
  '2710': 'Morph Sepolia',
  '2730': 'XR Sepolia',
  '2810': 'Morph Holesky',
  '3109': 'SatoshiVM Alpha Mainnet',
  '3110': 'SatoshiVM Testnet',
  '3141': 'Filecoin Hyperspace',
  '3737': 'Crossbell',
  '3776': 'Astar zkEVM',
  '3993': 'APEX Testnet',
  '4002': 'Fantom Testnet',
  '4090': 'Oasis Testnet',
  '4200': 'Merlin',
  '4201': 'LUKSO Testnet',
  '4202': 'Lisk Sepolia',
  '4242': 'Nexi',
  '4337': 'Beam',
  '4689': 'IoTeX',
  '4690': 'IoTeX Testnet',
  '4759': 'MEVerse Chain Testnet',
  '4777': 'BlackFort Exchange Network Testnet',
  '4999': 'BlackFort Exchange Network',
  '5000': 'Mantle',
  '5001': 'Mantle Testnet',
  '5003': 'Mantle Sepolia Testnet',
  '5112': 'Ham',
  '5165': 'Bahamut',
  '5611': 'opBNB Testnet',
  '5700': 'Syscoin Tanenbaum Testnet',
  '7000': 'ZetaChain',
  '7001': 'ZetaChain Athens Testnet',
  '7332': 'Horizen EON',
  '7518': 'MEVerse Chain Mainnet',
  '7560': 'Cyber',
  '7668': 'The Root Network',
  '7672': 'The Root Network - Porcini',
  '7700': 'Canto',
  '8082': 'Shardeum Sphinx',
  '8217': 'Klaytn',
  '8453': 'Base',
  '8899': 'JIBCHAIN L1',
  '9000': 'Evmos Testnet',
  '9001': 'Evmos',
  '9700': 'OORT MainnetDev',
  '10200': 'Gnosis Chiado',
  '11011': 'Shape Sepolia Testnet',
  '11124': 'Abstract Testnet',
  '11235': 'HAQQ Mainnet',
  '11501': 'BEVM Mainnet',
  '11822': 'Artela Testnet',
  '12306': 'Fibo Chain',
  '12324': 'L3X Protocol',
  '12325': 'L3X Protocol Testnet',
  '12553': 'RSS3 VSL Mainnet',
  '13337': 'Beam Testnet',
  '13371': 'Immutable zkEVM',
  '13381': 'Phoenix Blockchain',
  '13473': 'Immutable zkEVM Testnet',
  '15557': 'EOS EVM Testnet',
  '17000': 'Holesky',
  '17777': 'EOS EVM',
  '18233': 'Unreal',
  '22222': 'Nautilus Mainnet',
  '23294': 'Oasis Sapphire',
  '23295': 'Oasis Sapphire Testnet',
  '23451': 'DreyerX Mainnet',
  '23452': 'DreyerX Testnet',
  '25925': 'Bitkub Testnet',
  '28882': 'Boba Sepolia',
  '31337': 'Hardhat',
  '32769': 'Zilliqa',
  '33101': 'Zilliqa Testnet',
  '33979': 'Funki',
  '34443': 'Mode Mainnet',
  '35441': 'Q Mainnet',
  '35443': 'Q Testnet',
  '42161': 'Arbitrum One',
  '42170': 'Arbitrum Nova',
  '42220': 'Celo',
  '42766': 'ZKFair Mainnet',
  '42793': 'Etherlink',
  '43113': 'Avalanche Fuji',
  '43114': 'Avalanche',
  '43851': 'ZKFair Testnet',
  '44787': 'Alfajores',
  '48899': 'Zircuit Testnet',
  '50005': 'Yooldo Verse',
  '50006': 'Yooldo Verse Testnet',
  '53457': 'DODOchain Testnet',
  '53935': 'DFK Chain',
  '54211': 'HAQQ Testedge 2',
  '57000': 'Rollux Testnet',
  '58008': 'PGN ',
  '59140': 'Linea Goerli Testnet',
  '59141': 'Linea Sepolia Testnet',
  '59144': 'Linea Mainnet',
  '60808': 'BOB',
  '64240': 'Fantom Sonic Open Testnet',
  '80001': 'Polygon Mumbai',
  '80002': 'Polygon Amoy',
  '80084': 'Berachain bArtio',
  '80085': 'Berachain Artio',
  '81457': 'Blast',
  '84531': 'Base Goerli',
  '84532': 'Base Sepolia',
  '88882': 'Chiliz Spicy Testnet',
  '88888': 'Chiliz Chain',
  '88991': 'Jibchain Testnet',
  '100009': 'Vechain',
  '105105': 'Stratis Mainnet',
  '111188': 're.al',
  '128123': 'Etherlink Testnet',
  '167000': 'Taiko Mainnet',
  '167005': 'Taiko (Alpha-3 Testnet)',
  '167007': 'Taiko Jolnir (Alpha-5 Testnet)',
  '167008': 'Taiko Katla (Alpha-6 Testnet)',
  '167009': 'Taiko Hekla L2',
  '200810': 'Bitlayer Testnet',
  '200901': 'Bitlayer',
  '205205': 'Auroria Testnet',
  '314159': 'Filecoin Calibration',
  '421613': 'Arbitrum Goerli',
  '421614': 'Arbitrum Sepolia',
  '534351': 'Scroll Sepolia',
  '534352': 'Scroll',
  '641230': 'Bear Network Chain Mainnet',
  '660279': 'Xai Mainnet',
  '713715': 'Sei Devnet',
  '751230': 'Bear Network Chain Testnet',
  '808813': 'BOB Sepolia',
  '810180': 'zkLink Nova',
  '810181': 'zkLink Nova Sepolia Testnet',
  '984122': 'Forma',
  '984123': 'Forma Sketchpad',
  '1337803': 'Zhejiang',
  '1612127': 'PlayFi Albireo Testnet',
  '3397901': 'Funki Sepolia Sandbox',
  '3441005': 'Manta Pacific Testnet',
  '3441006': 'Manta Pacific Sepolia Testnet',
  '6038361': 'Astar zkEVM Testnet zKyoto',
  '7777777': 'Zora',
  '11155111': 'Sepolia',
  '11155420': 'OP Sepolia',
  '28122024': 'Ancient8 Testnet',
  '37084624': 'SKALE Nebula Testnet',
  '41144114': 'Otim Devnet',
  '111557560': 'Cyber Testnet',
  '161221135': 'Plume Testnet',
  '168587773': 'Blast Sepolia',
  '245022926': 'Neon EVM DevNet',
  '245022934': 'Neon EVM MainNet',
  '278611351': 'SKALE | Razor Network',
  '391845894': 'SKALE | Block Brawlers',
  '666666666': 'Degen',
  '888888888': 'Ancient8',
  '974399131': 'SKALE Calypso Testnet',
  '999999999': 'Zora Sepolia',
  '1020352220': 'SKALE Titan Hub',
  '1026062157': 'SKALE | CryptoBlades',
  '1032942172': 'SKALE | Crypto Colosseum',
  '1273227453': 'SKALE | Human Protocol',
  '1313161554': 'Aurora',
  '1313161555': 'Aurora Testnet',
  '1350216234': 'SKALE | Titan Community Hub',
  '1444673419': 'SKALE Europa Testnet',
  '1482601649': 'SKALE | Nebula Gaming Hub',
  '1564830818': 'SKALE | Calypso NFT Hub',
  '1666600000': 'Harmony One',
  '1802203764': 'Kakarot Sepolia',
  '2046399126': 'SKALE | Europa Liquidity Hub',
  '2139927552': 'SKALE | Exorde',
  '2716446429837000': 'Dchain',
  '2713017997578000': 'Dchain Testnet',
  '11297108109': 'Palm',
  '11297108099': 'Palm Testnet',
  '37714555429': 'Xai Testnet',
}
