import React, { useContext } from 'react'
import { CapacityCommitmentStake } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { useAccountModal } from '@rainbow-me/rainbowkit'

import { Button } from '../../components/Button'
import ConnectAccount from '../../components/ConnectAccount'
import { SignerContext } from '../../components/SignerProvider'
import { StakeModalContext } from '../../components/StakeModalProvider'
import { Text, UppercaseText } from '../../components/Text'
import { createDealClient } from '../../utils/createDealClient'
import { getCollateral } from '../../utils/getCollateral'

import { StakingModals, StakingStatus } from './StakingModals'
import { ButtonContainer } from './styled'

interface StakeInfoProps {
  capacityCommitment: CapacityCommitmentStake
}

export const StakeInfoConnect = () => {
  return (
    <ButtonContainer>
      <ConnectAccount />
      <Text size={12} color="grey400" align="center">
        Connect your wallet to stake tokens to Capacity commitment
      </Text>
    </ButtonContainer>
  )
}

export const ShouldHasNFT = () => {
  const { openAccountModal } = useAccountModal()

  return (
    <ButtonContainer>
      <Button variant="outline">
        <UppercaseText size={10} weight={700} onClick={openAccountModal}>
          NFT Required
        </UppercaseText>
      </Button>
      <Text size={12} color="grey400" align="center">
        You cannot stake FLT in the capacity commitment because your wallet does
        not contain Fluence NFTs. Only users with NFTs in their wallet can
        activate the Capacity Commitment.
      </Text>
    </ButtonContainer>
  )
}

export const Staked = ({ isMine }: { isMine?: boolean }) => {
  return (
    <ButtonContainer>
      <Button variant="success" disabled>
        <UppercaseText size={10} weight={700} color="green">
          Staked
        </UppercaseText>
      </Button>
      <Text size={12} color="grey400" align="center">
        {isMine ? 'You' : 'Someone'} have already Staked for this Capacity
        commitment. {isMine && 'You can interact with it in your dashboard!'}
      </Text>
    </ButtonContainer>
  )
}

export const StakeInfo: React.FC<StakeInfoProps> = ({ capacityCommitment }) => {
  const { data: staking, setCurrentId, setData } = useContext(StakeModalContext)
  const { signer, hasNFT } = useContext(SignerContext)

  if (!signer) {
    return <StakeInfoConnect />
  }

  if (!hasNFT) {
    return <ShouldHasNFT />
  }

  const isStaked = capacityCommitment?.status !== 'waitDelegation'

  const onOpenModal = async () => {
    const dealClient = await createDealClient(signer)
    const collateral = await getCollateral(dealClient, capacityCommitment.id)
    setData({
      status: 'waiting',
      title: '',
      expectedAPR: capacityCommitment.expectedAPR,
      id: capacityCommitment.id,
      collateral,
      duration: capacityCommitment.duration,
    })
  }

  function onClose() {
    setCurrentId('')
  }

  function onChangeStatus(
    status: StakingStatus,
    payload?: { transactionHash: string },
  ) {
    if (!staking) return

    setData({
      ...staking,
      ...payload,
      status,
    })
  }

  const isMine = capacityCommitment.staker === signer.address
  const isSpecifiedStaker =
    capacityCommitment.staker !== '0x0000000000000000000000000000000000000000'
  const canStake = !isSpecifiedStaker || isMine

  return (
    <ButtonContainer>
      {isStaked ? (
        <Staked isMine={isMine} />
      ) : (
        <>
          <Button
            variant={canStake ? 'black' : 'grey'}
            onClick={onOpenModal}
            disabled={!canStake}
          >
            Stake
          </Button>
          <Text size={12} color="grey400" align="center">
            {canStake
              ? 'Review the terms of capacity commitment before staking FLT tokens'
              : 'This Capacity Commitment is reserved for a specific staker by its provider. Please choose an another one!'}
          </Text>
        </>
      )}
      {staking && (
        <StakingModals
          onChangeStatus={onChangeStatus}
          onClose={onClose}
          signer={signer}
          staking={staking}
        />
      )}
    </ButtonContainer>
  )
}
