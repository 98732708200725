import { useEffect, useState } from 'react'
import { StakerClient } from '@fluencelabs/deal-ts-clients'

import { FLUENCE_CLIENT_NETWORK, RPC_URL } from '../constants/config'
import {
  FORMAT_NATIVE_TOKEN_TO_FIXED_DEFAULT,
  FORMAT_PAYMENT_TOKEN_TO_FIXED_DEFAULT,
} from '../constants/config.ts'

function deleteTrailZeros(s: string) {
  const arr = s.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)
  if (arr) {
    return arr[1] ?? '0'
  } else {
    return '0'
  }
}

export const formatNativeTokenValue = (value: string) => {
  // transform all 12.00000 -> to 12.0
  return deleteTrailZeros(
    parseFloat(value).toFixed(FORMAT_NATIVE_TOKEN_TO_FIXED_DEFAULT),
  )
}

// Add custom rules to format USD-like token values here.
export const formatPaymentTokenValue = (value: string) => {
  // transform all 12.00000 -> to 12
  return deleteTrailZeros(
    parseFloat(value).toFixed(FORMAT_PAYMENT_TOKEN_TO_FIXED_DEFAULT),
  )
}

export const useClient = () => {
  const [client, setClient] = useState<StakerClient>()

  useEffect(() => {
    ;(async () => {
      const settings = {
        nativeTokenValueAdditionalFormatter: formatNativeTokenValue,
        paymentTokenValueAdditionalFormatter: formatPaymentTokenValue,
        // TODO
        ratioToPercentFormatter: (ratio: number) => ratio.toString(),
      }
      const stakerClient = await StakerClient.create(
        FLUENCE_CLIENT_NETWORK,
        RPC_URL,
        undefined,
        settings,
      )
      setClient(stakerClient)
    })()
  }, [])

  return client
}
