import { CapacityCommitmentStatus } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'

import { Colors } from './colors'

type CapacityCommitmentStatusExpanded = CapacityCommitmentStatus | 'withdrawn'

export const STATUS_NAMES: {
  [status in CapacityCommitmentStatusExpanded]: string
} = {
  undefined: 'Unknown',
  waitDelegation: 'Created',
  waitStart: 'Funded',
  active: 'Active',
  inactive: 'Completed',
  failed: 'Failed',
  removed: 'Removed',
  withdrawn: 'Withdrawn',
}

export const STATUS_COLORS: {
  [status in CapacityCommitmentStatusExpanded]: Colors
} = {
  undefined: 'red',
  waitDelegation: 'grey300',
  waitStart: 'orange',
  active: 'green',
  inactive: 'blue',
  failed: 'red',
  removed: 'grey300',
  withdrawn: 'grey300',
}
