import { ContractMethodArgs } from '@fluencelabs/deal-ts-clients/dist/typechain-types/common'
import { Endpoint, schema } from '@rest-hooks/rest'
import { BytesLike, JsonRpcSigner } from 'ethers'

import { createDealClient } from '../utils/createDealClient'
import { getCollateral } from '../utils/getCollateral'

import { CapacityCommitment } from './CapacityCommitment'

export const stakeCollateralCapacityCommitment = new Endpoint(
  async ({
    capacityCommitmentId,
    signer,
  }: {
    capacityCommitmentId: string
    signer: JsonRpcSigner
  }) => {
    const dealClient = await createDealClient(signer)
    const capacity = dealClient.getCapacity()
    const commitments = [capacityCommitmentId]

    const commitmentsWithCollateral = await Promise.all(
      commitments.map(async (commitment) => {
        return await getCollateral(dealClient, commitment)
      }),
    )

    const collateralToApproveCommitment = commitmentsWithCollateral.reduce(
      (acc, collateral) => {
        return acc + collateral
      },
      0n,
    )

    const args: ContractMethodArgs<[commitmentIds: BytesLike[]], 'payable'> = [
      [capacityCommitmentId],
      {
        value: collateralToApproveCommitment,
        maxPriorityFeePerGas: 0,
      },
    ]

    const transaction = await capacity.depositCollateral(...args)
    await transaction.wait()

    return {
      capacityCommitment: {
        id: capacityCommitmentId,
        staker: signer.address,
        stakedCollateral: Number(collateralToApproveCommitment),
        currentCollateral: Number(collateralToApproveCommitment),
        status: 'waitStart',
      },
      transaction,
    }
  },
  {
    name: 'withdrawCollateralCapacityCommitment',
    schema: {
      capacityCommitment: CapacityCommitment,
      transaction: new schema.Object({}),
    },
  },
)
