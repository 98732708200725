import { ReactNode } from 'react'
import styled from '@emotion/styled'

import { AlertIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'
import { Text } from '../Text'

export const WarningContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  justify-content: center;
  padding: 18px 18px;
  gap: 16px;
  border-radius: 8px;

  border: 1px solid ${colors.grey200};

  min-height: 80px;
`

export function Warning({ children }: { children: ReactNode }) {
  return (
    <WarningContainer>
      <AlertIcon />
      <Text size={12}>{children}</Text>
    </WarningContainer>
  )
}
