import { ReactNode } from 'react'

import { Hint } from './Hint'
import { TextWithIcon, UppercaseText } from './Text'

export default function HintedText({
  text,
  hint,
  size = 10,
}: {
  text: ReactNode
  hint?: ReactNode
  size?: number
}) {
  return (
    <TextWithIcon>
      <UppercaseText size={size} color="grey400" weight={700}>
        {text}
      </UppercaseText>
      {hint && <Hint content={hint} />}
    </TextWithIcon>
  )
}
