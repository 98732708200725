import React, { Suspense, useContext, useState } from 'react'
import styled from '@emotion/styled'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import { RewardsStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import {
  CapacityCommitmentDashboard,
  StatusStatistics,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { useController, useLive } from '@rest-hooks/react'
import { useAccount } from 'wagmi'

import { ClientContext } from '../../components/ClientProvider'
import { Space } from '../../components/Space'
import { TableBody } from '../../components/Table'
import { Text } from '../../components/Text'
import WithdrawModalProvider from '../../components/WithdrawModalProvider'
import { useFilters } from '../../hooks/useFilters'

import { getCapacityCommitmentsDashboard } from '../../store/getCapacityCommitmentsDashboard'

import { WithdrawCollateralModals } from './Withdraw/WithdrawCollateralModals'
import { WithdrawRewardModals } from './Withdraw/WithdrawRewardModals'
import {
  CapacityCommitmentSort,
  CapacityCommitmentTable,
} from './CapacitiesTable'
import Information from './Information'

export const DashboardContent: React.FC = () => {
  const { address } = useAccount()
  const [filters, setFilter] = useFilters<CapacityCommitmentsDashboardFilter>({
    statuses: ['waitStart', 'active', 'inactive', 'failed', 'removed'],
  })

  const [order, setOrder] = useState<CapacityCommitmentSort>('createdAt:desc')
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitmentsDashboardOrderBy,
    OrderType,
  ]

  const client = useContext(ClientContext)
  const { capacityCommitments, statusSummary, rewardsSummary } = useLive(
    getCapacityCommitmentsDashboard,
    {
      stakerId: address,
      client,
      filters,
      orderBy,
      orderType,
    },
  )

  const ctrl = useController()
  const invalidateDashboard = () => {
    ctrl.invalidate(getCapacityCommitmentsDashboard, {
      stakerId: address,
      client,
      filters,
      orderBy,
      orderType,
    })
  }

  return (
    <WithdrawModalProvider>
      <DashboardInformationWrapper>
        <Information
          capacityCommitments={
            capacityCommitments as CapacityCommitmentDashboard[]
          }
          statusSummary={statusSummary as StatusStatistics}
          rewardsSummary={rewardsSummary as RewardsStatistics}
        />
      </DashboardInformationWrapper>
      <Space height="32px" />
      <Header>
        <Text size={32}>Capacity commitment</Text>
      </Header>
      <CapacityCommitmentTable
        orderBy={orderBy}
        orderType={orderType}
        filters={filters}
        setFilter={setFilter}
        setOrder={setOrder}
        capacityCommitments={
          capacityCommitments as CapacityCommitmentDashboard[]
        }
      />
      <WithdrawCollateralModals />
      <WithdrawRewardModals onSuccess={invalidateDashboard} />
    </WithdrawModalProvider>
  )
}

export const Dashboard = () => {
  return (
    <Suspense
      fallback={
        <>
          <DashboardInformationWrapper>
            <Information />
          </DashboardInformationWrapper>
          <Space height="32px" />
          <Header>
            <Text size={32}>Capacity commitment</Text>
          </Header>
          <TableBody skeletonCount={10} skeletonHeight={40} isLoading />
        </>
      }
    >
      <DashboardContent />
    </Suspense>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const DashboardInformationWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`
