import React from 'react'
import { Route } from 'wouter'

import { Layout } from './components/Layout'

import { Capacities } from './pages/capacities'
import { Dashboard } from './pages/dashboard'
import { ROUTES } from './constants'

import 'react-loading-skeleton/dist/skeleton.css'
import 'normalize.css'
import '@rainbow-me/rainbowkit/styles.css'
import './App.css'

export const App: React.FC = () => {
  return (
    <Layout>
      <Route path={ROUTES.stake} component={Capacities} />
      <Route path={ROUTES.dashboard} component={Dashboard} />
    </Layout>
  )
}
