import React, { useState } from 'react'
import styled from '@emotion/styled'

import { BurgerMenu } from '../assets/icons'

import { MenuModal } from './Modals/MenuModal'
import { Button } from './Button'
import ConnectAccount from './ConnectAccount'

export const RightMenu: React.FC = () => {
  const [open, setOpen] = useState(false)

  return (
    <RightMenuBlock>
      <ConnectAccount />
      <BurgerWrapper>
        <Button variant="outlineShadow" onClick={() => setOpen(true)}>
          <BurgerMenu />
        </Button>
        <MenuModal isVisible={open} onClose={() => setOpen(false)} />
      </BurgerWrapper>
    </RightMenuBlock>
  )
}

const BurgerWrapper = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: flex;
  }
`

const RightMenuBlock = styled.div`
  display: flex;
  gap: 6px;

  ${BurgerWrapper} {
  }
`
