import { ReactNode, useContext, useMemo } from 'react'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'

import { Button } from '../../../components/Button'
import { UppercaseText } from '../../../components/Text'
import { WithdrawModalContext } from '../../../components/WithdrawModalProvider'

export default function WithdrawRewardButton({
  capacityCommitments,
  children,
  disabled,
  title,
}: {
  title: string
  disabled?: boolean
  children: ReactNode
  capacityCommitments: CapacityCommitmentDashboard[]
}) {
  const filteredCapacityCommitments = useMemo(
    () => capacityCommitments.filter((cc) => cc.rewards.availableToClaim > 0),
    [capacityCommitments],
  )

  const { setData: setWithdrawInfo } = useContext(WithdrawModalContext)

  if (
    capacityCommitments.length < 1 ||
    filteredCapacityCommitments.length === 0
  )
    return null

  async function onOpenWithdraw() {
    setWithdrawInfo({
      id: 'rewards',
      status: 'waiting',
      type: 'rewards',
      capacityCommitments: filteredCapacityCommitments,
      title,
    })
  }

  return (
    <Button disabled={disabled} variant="outline" onClick={onOpenWithdraw}>
      <UppercaseText size={10} weight={700}>
        {children}
      </UppercaseText>
    </Button>
  )
}
