import React from 'react'
import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

import {
  CloseIcon,
  FailureIcon,
  SpinnerIcon,
  SuccessIcon,
} from '../../assets/icons'

import { colors } from '../../constants/colors'

import { BaseModal } from './BaseModal'

interface TransactionStatusModalProps {
  type: keyof typeof ICON_MAP
  isVisible: boolean
  children: React.ReactNode | React.ReactNode[]
  onClose?: () => void
}

const ICON_MAP = {
  success: SuccessIcon,
  failure: FailureIcon,
  pending: SpinnerIcon,
}

export const TransactionStatusModal: React.FC<TransactionStatusModalProps> = ({
  type,
  isVisible,
  children,
  onClose,
}) => {
  const Icon = ICON_MAP[type]

  return (
    <BaseModal isVisible={isVisible} onClose={onClose}>
      <ModalRoot>
        <ModalHeader>
          <Dialog.Close>
            <CloseIconStyled onClick={onClose} />
          </Dialog.Close>
        </ModalHeader>
        <ModalContent>
          <Icon />
          {children}
        </ModalContent>
        <ModalFooter></ModalFooter>
      </ModalRoot>
    </BaseModal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  height: 48px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  height: 40px;
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`
