import React, { Suspense, useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { CapacityCommitmentsStakerFilters } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'

import { Search } from '../../components/Search'
import { SignerContext } from '../../components/SignerProvider'
import { Space } from '../../components/Space'
import StakeModalProvider from '../../components/StakeModalProvider'
import { Switch } from '../../components/Switch'
import { TableBody } from '../../components/Table'
import { Text } from '../../components/Text'
import { useFilters } from '../../hooks/useFilters'

import { CapacityCommitmentTable } from './CapacitiesTable'

export const Capacities: React.FC = () => {
  const { signer } = useContext(SignerContext)
  const [filters, setFilter] = useFilters<CapacityCommitmentsStakerFilters>()

  useEffect(() => {
    if (
      filters.staker &&
      filters.staker.toLowerCase() !== signer?.address.toLowerCase()
    )
      setFilter('staker', signer?.address.toLowerCase())
  }, [filters.staker, signer?.address])

  return (
    <StakeModalProvider>
      <Header>
        <Text size={32}>Capacity commitment</Text>
        <FiltersBlock>
          <Search
            value={filters.searchBy ?? ''}
            onChange={(search) => setFilter('searchBy', search)}
            placeholder="Search by Commitment ID / Provider ID / Provider name"
          />
        </FiltersBlock>
      </Header>

      {signer?.address && (
        <>
          <Space height="16px" />
          <Switch
            label="Only for me"
            hint="Shows Capacity Commitments where Providers have set your wallet address as the sole possible staker"
            value={filters.staker === undefined ? false : !!filters.staker}
            onSwitch={(value) =>
              setFilter(
                'staker',
                value ? signer?.address.toLowerCase() : undefined,
              )
            }
          />
        </>
      )}

      <Space height="68px" />
      <Suspense fallback={<TableBody isLoading />}>
        <CapacityCommitmentTable filters={filters} />
      </Suspense>
    </StakeModalProvider>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;

  @media (max-width: 900px) {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    gap: 16px;
  }
`

const FiltersBlock = styled.div`
  display: flex;
  width: 420px;
  max-width: 100%;
`
