import {
  CapacityCommitmentStatus,
  RewardsStatistics,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import { ProviderInfo } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { Entity } from '@rest-hooks/rest'

export class CapacityCommitment extends Entity {
  readonly id!: string
  readonly duration!: number
  readonly staker?: string
  readonly status!: CapacityCommitmentStatus
  readonly stakingReward!: number

  readonly providerName?: string
  readonly providerAddress?: string
  readonly startAt?: number | null
  readonly finishAt?: number | null
  readonly totalCU?: number
  readonly stakedCollateral?: number
  readonly currentCollateral?: number
  readonly collateralWithdrawn?: boolean
  readonly slashedProofs?: number
  readonly totalPossibleProofs?: number
  readonly rewards?: RewardsStatistics
  readonly averageAPR?: number

  readonly reqCollateral?: string
  readonly expectedAPR?: string
  readonly createdAt?: number
  readonly peerId?: string
  readonly providerInfo?: ProviderInfo

  pk() {
    return this.id.toLowerCase()
  }

  static key = 'CapacityCommitment'
}
