import { useContext } from 'react'
import { getDeployment } from '@fluencelabs/deal-ts-clients'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { toFunctionSelector } from 'viem'
import { useCall, useReadContract } from 'wagmi'

import { ClientContext } from '../components/ClientProvider'

import { FLUENCE_CLIENT_NETWORK, WAGMI_CONFIG } from '../constants/config'

const abi = [
  {
    constant: true,
    inputs: [
      {
        name: 'commitmentId',
        type: 'bytes32',
      },
    ],
    name: 'getCommitment',
    outputs: [
      {
        components: [
          {
            name: 'status',
            type: 'uint8',
          },
          {
            name: 'peerId',
            type: 'bytes32',
          },
          {
            name: 'collateralPerUnit',
            type: 'uint256',
          },
          {
            name: 'unitCount',
            type: 'uint256',
          },
          {
            name: 'startEpoch',
            type: 'uint256',
          },
          {
            name: 'endEpoch',
            type: 'uint256',
          },
          {
            name: 'rewardDelegatorRate',
            type: 'uint256',
          },
          {
            name: 'delegator',
            type: 'address',
          },
          {
            name: 'totalFailCount',
            type: 'uint256',
          },
          {
            name: 'failedEpoch',
            type: 'uint256',
          },
          {
            name: 'exitedUnitCount',
            type: 'uint256',
          },
        ],
        name: '',
        type: 'tuple',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
]

export const useWithdrawEpochs = (cc: CapacityCommitmentDashboard) => {
  const client = useContext(ClientContext)

  const deployment = getDeployment(FLUENCE_CLIENT_NETWORK)
  const core = deployment.core as `0x${string}`
  const capacity = deployment.capacity as `0x${string}`

  const withdrawEpochsAfterFailed = useCall({
    config: WAGMI_CONFIG,
    to: core,
    data: toFunctionSelector('withdrawEpochsAfterFailed()'),
  })

  const capacityCommitment = useReadContract({
    config: WAGMI_CONFIG,
    abi,
    address: capacity,
    functionName: 'getCommitment',
    args: [cc.id],
  })

  if (!client || !capacityCommitment.data) return null

  const failedEpoch = Number(
    (capacityCommitment.data as { failedEpoch: string })?.failedEpoch,
  )

  const startEpoch = Number(
    (capacityCommitment.data as { startEpoch: string })?.startEpoch,
  )

  const withdrawEpochsAfterFailedValue = Number(
    withdrawEpochsAfterFailed.data?.data,
  )

  const period =
    withdrawEpochsAfterFailedValue * client.getEpochDuration() * 1000

  if (failedEpoch < startEpoch)
    return {
      period,
      end: Date.now(),
    }

  return {
    period,
    end:
      (client.calculateTimestamp(failedEpoch) +
        withdrawEpochsAfterFailedValue * client.getEpochDuration()) *
      1000,
  }
}
