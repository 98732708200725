import React, { FC, ReactNode } from 'react'
import { CustomScroll } from 'react-custom-scroll'
import styled from '@emotion/styled'

import { CloseIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'
import { Button } from '../Button'
import { Text } from '../Text'

import { BaseModal } from './BaseModal'
import { Close } from './styled'

export interface WithdrawModalFooterProps {
  handleClose: () => void
  handleSubmit: () => void
}

interface WithdrawModalProps {
  isVisible: boolean
  onClose?: () => void
  onSubmit?: () => void
  title: ReactNode
  warning?: ReactNode
  children?: ReactNode
  contentMinHeight?: string
  footer?: FC<WithdrawModalFooterProps>
}

function DefaultFooter({
  handleSubmit,
  handleClose,
}: WithdrawModalFooterProps) {
  return (
    <ModalFooter>
      <ButtonsContainer>
        <Button variant="grey" isLarge onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="black" isLarge onClick={handleSubmit}>
          Withdraw
        </Button>
      </ButtonsContainer>
    </ModalFooter>
  )
}

export const WithdrawModal: React.FC<WithdrawModalProps> = ({
  isVisible,
  onSubmit,
  onClose,
  title,
  warning,
  children,
  contentMinHeight,
  footer = DefaultFooter,
}) => {
  const handleClose = () => {
    onClose?.()
  }

  const handleSubmit = () => {
    onSubmit?.()
  }

  return (
    <BaseModal isVisible={isVisible} maxWidth={440} onClose={onClose}>
      <ModalRoot>
        <ModalHeader>
          <InfoTextContainer>{title}</InfoTextContainer>
          <Close>
            <CloseIconStyled onClick={onClose} />
          </Close>
        </ModalHeader>
        <Divider />
        <CustomScroll heightRelativeToParent={contentMinHeight}>
          <ModalContent>
            {children}
            {warning}
          </ModalContent>
        </CustomScroll>
        {footer({ handleClose, handleSubmit })}
      </ModalRoot>
    </BaseModal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 24px;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 24px 0;
  height: 48px;
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`

const Divider = styled.div`
  border: 1px solid ${colors.grey100};
  width: 100%;
`

const InfoTextContainer = styled.div`
  & > ${Text} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;

  width: 100%;

  padding: 0 32px;

  & > button:last-of-type {
    width: 100%;
  }
`
