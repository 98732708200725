import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { Chain } from 'viem'

// The network name for fluence contract clients: {kras, dar, stage, local}
export const FLUENCE_CLIENT_NETWORK =
  import.meta.env.VITE_FLUENCE_CLIENT_NETWORK ?? 'stage'
export const BLOCKSCOUT_URL =
  import.meta.env.VITE_BLOCKSCOUT_URL ?? 'https://blockscout.stage.fluence.dev/'
export const RPC_URL =
  import.meta.env.VITE_RPC_URL ?? 'https://rpc.stage.fluence.dev'
export const BRIDGE_URL =
  import.meta.env.VITE_BRIDGE_URL ?? 'https://bridge.stage.fluence.dev'
export const VITE_EXPLORER_URL =
  import.meta.env.VITE_EXPLORER_URL ?? 'https://explorer.stage.fluence.dev'
export const CHAIN_ID = parseInt(
  import.meta.env.VITE_CHAIN_ID ?? '123420000220',
)
export const NFT_ADDRESS =
  import.meta.env.VITE_NFT_ADDRESS ??
  '0x980bee75bcf7560fb5006b8f278887244188ae4a'

export const FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT =
  import.meta.env.VITE_FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT != undefined
    ? import.meta.env.VITE_FILTER_ONLY_APPROVED_PROVIDERS_DEFAULT == 'true'
    : true
export const FORMAT_PAYMENT_TOKEN_TO_FIXED_DEFAULT = 3
export const FORMAT_NATIVE_TOKEN_TO_FIXED_DEFAULT = 6

export const CHAIN = {
  id: CHAIN_ID,
  name: FLUENCE_CLIENT_NETWORK,
  nativeCurrency: { name: 'FLT', symbol: 'FLT', decimals: 18 },
  rpcUrls: {
    default: { http: [RPC_URL] },
  },
  blockExplorers: {
    default: {
      name: 'BlockScout',
      url: BLOCKSCOUT_URL,
    },
  },
} as const satisfies Chain

export const WAGMI_CONFIG = getDefaultConfig({
  appName: 'Fluence Staking',
  projectId: 'YOUR_PROJECT_ID',
  chains: [CHAIN],
})
