import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'

import { Copyable } from '../../components/Copyable'
import { Cell, Row, RowBlock } from '../../components/Table'
import { Text } from '../../components/Text'
import TokenBalance from '../../components/TokenBalance'
import { getShortAddress } from '../../utils/getShortAddress'

import { RowContainer, RowHeaderBase } from './styled'

const ROW_TEMPLATE = ['minmax(250px, 1fr)', 'minmax(55px, 1fr)']

export function CommitmentsWithRewards({
  capacityCommitments,
}: {
  capacityCommitments: CapacityCommitmentDashboard[]
}) {
  return (
    <RowContainer>
      {capacityCommitments.map((capacityCommitment) => (
        <RowBlock key={capacityCommitment.id}>
          <RowHeaderBase>
            <Row template={ROW_TEMPLATE}>
              <Cell>
                <Copyable value={capacityCommitment.id}>
                  <Text size={12} color="blue">
                    {getShortAddress(capacityCommitment.id, 25)}
                  </Text>
                </Copyable>
              </Cell>
              <Cell justifyContent="right">
                <TokenBalance
                  balance={capacityCommitment.rewards.availableToClaim}
                />
              </Cell>
            </Row>
          </RowHeaderBase>
        </RowBlock>
      ))}
    </RowContainer>
  )
}
