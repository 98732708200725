import { StakerClient } from '@fluencelabs/deal-ts-clients'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { Endpoint, schema } from '@rest-hooks/rest'

import { CapacityCommitment } from './CapacityCommitment'

export const getCapacityCommitmentsDashboard = new Endpoint(
  async ({
    stakerId,
    filters,
    orderType,
    orderBy,
    client,
  }: {
    stakerId?: string
    orderBy?: CapacityCommitmentsDashboardOrderBy
    filters?: CapacityCommitmentsDashboardFilter
    orderType?: OrderType
    client?: StakerClient
  }) => {
    const defaultValue = {
      capacityCommitments: [],
      statusSummary: {
        active: 0,
        inactive: 0,
        failed: 0,
        waitStart: 0,
        removed: 0,
      },
      rewardsSummary: {
        inVesting: 0,
        availableToClaim: 0,
        claimed: 0,
      },
    }
    if (!client) return defaultValue
    try {
      const data = await client.getCapacityCommitmentsDashboard(
        stakerId,
        filters,
        orderBy,
        orderType,
      )

      return {
        capacityCommitments: data.data,
        statusSummary: data.statusSummary,
        rewardsSummary: data.rewardsSummary,
      }
    } catch (e) {
      console.error(e)
      return defaultValue
    }
  },
  {
    name: 'getCapacityCommitmentsDashboard',
    schema: {
      capacityCommitments: [CapacityCommitment],
      statusSummary: new schema.Object({}),
      rewardsSummary: new schema.Object({}),
    },
    pollFrequency: 60000,
  },
)
