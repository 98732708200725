import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'wouter'

import { CloseIcon } from '../../assets/icons'
import { usePrevious } from '../../hooks/usePrevious'

import { colors } from '../../constants/colors'
import { Menu, MenuBlock, StyledActiveLink, StyledLink } from '../Menu'

import { BaseModal } from './BaseModal'
import { Close } from './styled'

interface StakeModalProps {
  isVisible: boolean
  onClose?: () => void
}

export const MenuModal: React.FC<StakeModalProps> = ({
  isVisible,
  onClose,
}) => {
  const [location] = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    if (onClose && prevLocation !== location) onClose()
  }, [location, onClose, prevLocation])

  return (
    <BaseModal isVisible={isVisible} maxWidth={440} onClose={onClose}>
      <ModalRoot>
        <ModalContent>
          <Close>
            <CloseIconStyled onClick={onClose} />
          </Close>
          <Menu />
        </ModalContent>
      </ModalRoot>
    </BaseModal>
  )
}

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
  flex-grow: 1;

  ${MenuBlock} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }

  ${StyledLink}, ${StyledActiveLink} {
    font-size: 32px;
  }

  ${Close} {
    z-index: 1;
  }
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;

  @media (max-width: 500px) {
    min-height: 100vh;
  }
`
