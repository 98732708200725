import React, { useContext } from 'react'
import { CapacityCommitmentStake } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import * as Accordion from '@radix-ui/react-accordion'
import { formatDistanceToNowStrict } from 'date-fns'

import { A } from '../../components/A'
import { ClientContext } from '../../components/ClientProvider'
import { ProviderIcon } from '../../components/ProviderIcon'
import { Space } from '../../components/Space'
import { Cell, Row, RowBlock } from '../../components/Table'
import { ShrinkText, Text } from '../../components/Text'
import TokenBalance from '../../components/TokenBalance'
import formatPercent from '../../utils/formatPercent'
import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'

import { CapacityCommitmentRowContent } from './CapacityCommitmentRowContent'
import {
  AccordionContent,
  CaretIconStyled,
  ROW_TEMPLATE,
  RowHeader,
} from './styled'

interface CapacityCommitmentRowProps {
  capacityCommitment: CapacityCommitmentStake
  toggle: (id: string) => void
}

export const CapacityCommitmentRow: React.FC<CapacityCommitmentRowProps> = ({
  toggle,
  capacityCommitment,
}) => {
  const client = useContext(ClientContext)

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation()
    toggle(capacityCommitment.id)
  }

  if (!client) return null

  const stakingDuration =
    capacityCommitment.duration * client.getEpochDuration() * 1000

  return (
    <Accordion.Item value={capacityCommitment.id}>
      <RowBlock>
        <RowHeader asChild onClick={handleOpen}>
          <Row template={ROW_TEMPLATE}>
            <Cell>
              <A
                href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}
              >
                {getShortAddress(capacityCommitment.id, 25)}
              </A>
            </Cell>
            <Cell>
              <A
                href={`${VITE_EXPLORER_URL}/provider/${capacityCommitment.providerInfo.address}`}
              >
                <ProviderIcon
                  address={capacityCommitment.providerInfo.address}
                />
              </A>
              <Space width="8px" />
              <A
                href={`${VITE_EXPLORER_URL}/provider/${capacityCommitment.providerInfo.address}`}
              >
                <ShrinkText size={12} color="blue">
                  <Text size={14}>{capacityCommitment.providerInfo.name}</Text>
                </ShrinkText>
              </A>
            </Cell>
            <Cell>
              <Text size={12}>
                {formatDistanceToNowStrict(Date.now() + stakingDuration)}
              </Text>
            </Cell>
            <Cell gap="6px" justifyContent="right">
              <TokenBalance
                size="small"
                balance={capacityCommitment.reqCollateral}
              />
            </Cell>
            <Cell>
              <Text size={12}>
                {formatPercent(capacityCommitment.stakingReward)}%
              </Text>
            </Cell>
            <Cell>
              <Text size={12}>
                {formatPercent(capacityCommitment.expectedAPR)}%
              </Text>
            </Cell>
            <Cell>
              <CaretIconStyled />
            </Cell>
          </Row>
        </RowHeader>
        <AccordionContent>
          <CapacityCommitmentRowContent
            capacityCommitment={capacityCommitment}
          />
        </AccordionContent>
      </RowBlock>
    </Accordion.Item>
  )
}
