import styled from '@emotion/styled'
import { AvatarComponent } from '@rainbow-me/rainbowkit'

import { Logo } from '../assets/icons'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #00b3ff 0%, #d57dff 100%);
  color: #fff;
  height: 100%;
  width: 100%;
`

export const CustomAvatar: AvatarComponent = () => {
  return (
    <Wrapper>
      <Logo />
    </Wrapper>
  )
}
