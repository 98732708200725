import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  CapacityCommitmentsStatusFilter,
  OrderType,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsDashboardFilter,
  CapacityCommitmentsDashboardOrderBy,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import * as Accordion from '@radix-ui/react-accordion'

import { Hint } from '../../components/Hint'
import { Pagination } from '../../components/Pagination'
import { Select } from '../../components/Select'
import { Space } from '../../components/Space'
import {
  TableBody,
  TableColumnTitle,
  TableColumnTitleWithSort,
  TableHeader,
  TableResponsive,
  TableResponsiveWrapper,
} from '../../components/Table'
import { UppercaseText } from '../../components/Text'
import { usePagination } from '../../hooks'
import { SetFilter } from '../../hooks/useFilters'

import { STATUS_NAMES } from '../../constants/statusesMap'

import { CapacityCommitmentRow, ROW_TEMPLATE } from './CapacityCommitmentRow'
import { StatusContainer } from './styled'

const CAPACITY_COMMITMENTS_PER_PAGE = 15

export type CapacityCommitmentSort =
  `${CapacityCommitmentsDashboardOrderBy}:${OrderType}`

const STATUS_ITEMS: {
  value: CapacityCommitmentsStatusFilter | 'all'
  label: string
}[] = [
  { value: 'all', label: 'All' },
  { value: 'active', label: STATUS_NAMES['active'] },
  { value: 'inactive', label: STATUS_NAMES['inactive'] },
  { value: 'failed', label: STATUS_NAMES['failed'] },
  { value: 'removed', label: STATUS_NAMES['removed'] },
  { value: 'waitStart', label: STATUS_NAMES['waitStart'] },
]

export const CapacityCommitmentTable: React.FC<{
  filters: CapacityCommitmentsDashboardFilter
  setFilter: SetFilter<CapacityCommitmentsDashboardFilter>
  capacityCommitments?: CapacityCommitmentDashboard[]
  setOrder: (order: CapacityCommitmentSort) => void
  orderType: OrderType
  orderBy: CapacityCommitmentsDashboardOrderBy
  isLoading?: boolean
}> = ({
  setFilter,
  capacityCommitments,
  setOrder,
  isLoading,
  filters,
  orderType,
  orderBy,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([])

  const { page, selectPage, limit, offset, getTotalPages } = usePagination(
    CAPACITY_COMMITMENTS_PER_PAGE,
  )

  const hasNextPage = capacityCommitments && capacityCommitments.length > limit
  const pageCapacityCommitments =
    capacityCommitments && capacityCommitments.slice(offset, offset + limit)

  const toggle = (id: string) => {
    if (isAccordionOpen.includes(id)) {
      return setIsAccordionOpen((arr) => arr.filter((v) => v !== id))
    }

    setIsAccordionOpen([...isAccordionOpen, id])
  }

  const handleSort = (
    key: CapacityCommitmentsDashboardOrderBy,
    order: OrderType,
  ) => {
    setOrder(`${key}:${order}`)
  }

  const handleSetStatus = (value: CapacityCommitmentsStatusFilter | 'all') => {
    const statuses =
      value === 'all'
        ? ([
            'waitStart',
            'active',
            'inactive',
            'failed',
            'removed',
          ] as CapacityCommitmentsStatusFilter[])
        : [value]
    setFilter('statuses', statuses)
  }

  let statusFilter: CapacityCommitmentsStatusFilter | 'all' = 'all'
  if (filters?.statuses && filters.statuses[0]) {
    statusFilter = filters?.statuses.length > 1 ? 'all' : filters?.statuses[0]
  }

  return (
    <>
      <Space height="12px" />
      <StatusContainer>
        <UppercaseText size={10} weight={600} color="grey400">
          Status
        </UppercaseText>
        <Select
          value={statusFilter}
          buttonVariant="outlineShadow"
          onChange={handleSetStatus}
          items={STATUS_ITEMS}
        />
      </StatusContainer>
      <Space height="32px" />
      <TableResponsiveWrapper>
        <TableResponsive minWidth="960px">
          <TableHeader template={ROW_TEMPLATE}>
            <TableColumnTitle>Capacity Commitment Id</TableColumnTitle>
            <TableColumnTitle>Provider Name</TableColumnTitle>
            <TableColumnTitleWithSort
              order={orderType}
              field="expiresIn"
              isActive={orderBy === 'expiresIn'}
              onSort={handleSort}
              hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
            >
              Expiration
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field="stakingReward"
              isActive={orderBy === 'stakingReward'}
              onSort={handleSort}
              hint="The share of rewards the staker earns for Capacity Commitment, defined by provider"
            >
              Staking Reward
            </TableColumnTitleWithSort>
            <Hint content="The current status of the CC">
              <TableColumnTitle>Status</TableColumnTitle>
            </Hint>
          </TableHeader>
          <Accordion.Root type="multiple" value={isAccordionOpen}>
            <TableBody
              skeletonCount={CAPACITY_COMMITMENTS_PER_PAGE}
              skeletonHeight={40}
              isLoading={isLoading}
            >
              {pageCapacityCommitments?.map((capacity) => (
                <CapacityCommitmentRow
                  key={capacity.id}
                  capacityCommitment={capacity}
                  toggle={toggle}
                />
              ))}
            </TableBody>
          </Accordion.Root>
        </TableResponsive>
      </TableResponsiveWrapper>
      <Space height="32px" />
      <div style={{ alignSelf: 'flex-end' }}>
        {!capacityCommitments ? (
          <Skeleton width={200} height={34} count={1} />
        ) : (
          <Pagination
            pages={getTotalPages(capacityCommitments.length)}
            page={page}
            hasNextPage={hasNextPage}
            onSelect={selectPage}
          />
        )}
      </div>
    </>
  )
}
