export enum ChainId {
  MUMBAI = 80001,
}

export const SUPPORTED_CHAINS = []

export const RPC: Record<ChainId, string> = {
  [ChainId.MUMBAI]: 'https://polygon-mumbai.gateway.tenderly.co',
}

export const ROUTES = {
  stake: '/',
  dashboard: '/dashboard',
} as const

export type Routes = typeof ROUTES
