import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

import { Hint } from '../../components/Hint'
import { Text, UppercaseText } from '../../components/Text'
import TokenBalance from '../../components/TokenBalance'

import { colors } from '../../constants/colors'

interface InformationBlockProps {
  title: string
  action?: ReactNode
  data: {
    label: string
    value: number | string
    token?: string
    hint?: string
  }[]
}

export const InformationBlock: React.FC<InformationBlockProps> = ({
  title,
  data,
  action,
}) => (
  <InformationContainer>
    <InformationBlockHeader>
      <Text>{title}</Text>
      {action}
    </InformationBlockHeader>
    <Infos count={data.length}>
      {data.map(({ label, value, token, hint }) => (
        <InfoColumn key={label}>
          <Hint content={hint}>
            <UppercaseText color="grey400" weight={600} size={10}>
              {label}
            </UppercaseText>
          </Hint>
          {token && <TokenBalance size="big" balance={value} />}
          {!token && <Count>{value}</Count>}
        </InfoColumn>
      ))}
    </Infos>
  </InformationContainer>
)

const Count = styled(Text)`
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

const InformationBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Text} {
    font-size: 24px;
  }

  @media (max-width: 900px) {
    ${Text} {
      font-size: 20px;
    }
  }
`

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 8px;
  background-color: ${colors.grey100};

  gap: 32px;

  padding: 16px 24px;

  @media (max-width: 900px) {
    gap: 16px;
  }
`

const Infos = styled.div<{ count: number }>`
  ${({ count }) => `
    display: grid;

    grid-template-columns: ${'1fr '.repeat(count)};
    grid-template-rows: 1fr;
    gap: 25px;

    @media (max-width: 950px) {
      grid-template-rows: ${'1fr '.repeat((count - (count % 2)) / count)};
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-rows: ${'1fr '.repeat(count)};
      grid-template-columns: 1fr;
      gap: 8px;
    }
  `}
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
  }
`
