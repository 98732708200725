import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

import { Text } from '../Text'

export const Close = styled(Dialog.Close)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const TransactionStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & > ${Text}:last-of-type {
    margin-top: 58px;
  }
`

export const TransactionStatusTextContainer = styled.div`
  width: 390px;

  & > ${Text} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`

export const TransactionStatusBadge = styled.div`
  display: flex;
  align-items: center;
  width: min-content;
  gap: 8px;
  margin: 0 10px;
`
