import { useMemo } from 'react'
import { erc721Abi } from 'viem'
import { useAccount, useContractRead } from 'wagmi'

import { NFT_ADDRESS } from '../constants/config'

export default function useNFT() {
  const { address } = useAccount()

  const { data: balance } = useContractRead({
    address: NFT_ADDRESS,
    abi: erc721Abi,
    functionName: 'balanceOf',
    args: [address ?? '0x'],
  })

  return useMemo(() => typeof balance === 'bigint' && balance > 0n, [balance])
}
