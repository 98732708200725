import { createContext, ReactNode, useMemo } from 'react'
import { JsonRpcSigner } from 'ethers'

import { useEthersSigner } from '../hooks/useEthersSigner'
import useNFT from '../hooks/useNFT'

export const SignerContext = createContext<{
  signer?: JsonRpcSigner
  hasNFT?: boolean
}>({})

export default function SignerProvider({ children }: { children: ReactNode }) {
  const signer = useEthersSigner()
  const hasNFT = useNFT()
  const signerContextValue = useMemo(
    () => ({ signer, hasNFT }),
    [signer, hasNFT],
  )

  return (
    <SignerContext.Provider value={signerContextValue}>
      {children}
    </SignerContext.Provider>
  )
}
