import React from 'react'
import { CapacityCommitmentStake } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { formatDistanceToNowStrict } from 'date-fns'

import { Copyable } from '../../components/Copyable'
import HintedText from '../../components/HintedText'
import { Text } from '../../components/Text'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'
import { getShortAddress } from '../../utils/getShortAddress'

import { StakeInfo } from './StakeInfo'
import {
  CapacityCommitmentContentBlock,
  CapacityCommitmentInformation,
  InformationRow,
} from './styled'

interface CapacityCommitmentRowContentProps {
  capacityCommitment: CapacityCommitmentStake
}

export const CapacityCommitmentRowContent: React.FC<
  CapacityCommitmentRowContentProps
> = ({ capacityCommitment }) => {
  return (
    <CapacityCommitmentContentBlock>
      <CapacityCommitmentInformation>
        <Text size={16}>Commitment information</Text>
        <InformationRow>
          <HintedText text="Created At" />
          <Text size={14}>
            {formatUnixTimestamp(capacityCommitment.createdAt).date}
          </Text>
        </InformationRow>
        <InformationRow>
          <HintedText text="Peer ID" />
          <Copyable value={capacityCommitment.peerId}>
            <Text size={14}>
              {getShortAddress(capacityCommitment.peerId, 24)}
            </Text>
          </Copyable>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Compute capacity"
            hint="Number of CUs this CC contributes to the network. (1 CU = 1 CPU core + 4 GB RAM)"
          />
          <Text size={14}>{capacityCommitment.totalCU} CUs cores</Text>
        </InformationRow>
      </CapacityCommitmentInformation>
      <CapacityCommitmentInformation>
        <Text size={16}>Provider information</Text>
        <InformationRow>
          <HintedText
            text="Provider lifetime"
            hint="How long this provider has been active in the Fluence network"
          />
          <Text size={14}>
            {formatDistanceToNowStrict(
              new Date(capacityCommitment.providerInfo.createdAt * 1000),
            )}
          </Text>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Total CCs over time"
            hint="Number of Capacity Commitments this provider has created. View full history at Fluence Explorer"
          />
          <Text size={14}>{capacityCommitment.providerInfo.totalCCs}</Text>
        </InformationRow>
      </CapacityCommitmentInformation>
      <CapacityCommitmentInformation>
        <StakeInfo capacityCommitment={capacityCommitment} />
      </CapacityCommitmentInformation>
    </CapacityCommitmentContentBlock>
  )
}
