import React from 'react'
import styled from '@emotion/styled'
import * as Dialog from '@radix-ui/react-dialog'

import { CloseIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'
import { Text } from '../Text'

import { BaseModal } from './BaseModal'

interface TransactionWaitingModalProps {
  isVisible: boolean
  onClose?: () => void
  description: string
}

export const TransactionWaitingModal: React.FC<
  TransactionWaitingModalProps
> = ({ isVisible, onClose, description }) => {
  return (
    <BaseModal isVisible={isVisible} onClose={onClose}>
      <ModalRoot>
        <ModalHeader>
          <Dialog.Close>
            <CloseIconStyled onClick={onClose} />
          </Dialog.Close>
        </ModalHeader>
        <ModalContent>
          <Spinner />
          <TextContainer>
            <Text size={20}>Transaction in progress</Text>
            <Text size={14} color="grey500">
              {description}
            </Text>
          </TextContainer>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </ModalRoot>
    </BaseModal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  height: 48px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  height: 40px;
`

const ModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
`

const Spinner = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid ${colors.grey400};
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(10px);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const CloseIconStyled = styled(CloseIcon)`
  color: ${colors.grey400};
  cursor: pointer;
`
