import { DealClient } from '@fluencelabs/deal-ts-clients'

export async function getCollateral(
  dealClient: DealClient,
  commitmentId: string,
) {
  const capacity = dealClient.getCapacity()
  const commitment = await capacity.getCommitment(commitmentId)
  return commitment.collateralPerUnit * commitment.unitCount
}
