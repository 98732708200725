import { Endpoint } from '@rest-hooks/rest'
import { JsonRpcSigner } from 'ethers'

import { createDealClient } from '../utils/createDealClient'

import { CapacityCommitment } from './CapacityCommitment'

export const withdrawCollateralCapacityCommitment = new Endpoint(
  async (
    {
      capacityCommitment,
      signer,
    }: {
      capacityCommitment: CapacityCommitment
      signer: JsonRpcSigner
    },
    body: Partial<CapacityCommitment>,
  ) => {
    const defaultResult = {
      id: capacityCommitment.id,
    }

    if (
      capacityCommitment.status !== 'failed' &&
      capacityCommitment.status !== 'inactive'
    )
      return defaultResult

    const dealClient = await createDealClient(signer)
    const capacity = dealClient.getCapacity()

    const tx = await capacity.withdrawCollateral(capacityCommitment.id, {
      maxPriorityFeePerGas: 0,
    })

    await tx.wait()

    return {
      id: capacityCommitment.id,
      ...body,
    }
  },
  {
    name: 'withdrawCollateralCapacityCommitment',
    schema: CapacityCommitment,
  },
)
