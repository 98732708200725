import {
  TransactionStatusContainer,
  TransactionStatusTextContainer,
} from '../../components/Modals/styled'
import { TransactionStatusModal } from '../../components/Modals/TransactionStatusModal'
import { Text } from '../../components/Text'

export default function WithdrawTransactionSuccessModal({
  title,
  onClose,
}: {
  title: string
  onClose: () => void
}) {
  return (
    <TransactionStatusModal type="success" isVisible onClose={onClose}>
      <TransactionStatusContainer>
        <TransactionStatusTextContainer>
          <Text size={20} color="grey500" align="center">
            {title}
          </Text>
        </TransactionStatusTextContainer>
      </TransactionStatusContainer>
    </TransactionStatusModal>
  )
}
