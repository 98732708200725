import { useContext } from 'react'

import { TransactionWaitingModal } from '../../../components/Modals/TransactionWaitingModal'
import { SignerContext } from '../../../components/SignerProvider'
import {
  WithdrawModalContext,
  WithdrawStatus,
} from '../../../components/WithdrawModalProvider'
import { getShortAddress } from '../../../utils/getShortAddress'

import WithdrawTransactionFailedModal from '../WithdrawTransactionFailedModal'
import WithdrawTransactionSuccessModal from '../WithdrawTransactionSuccessModal'

import { WithdrawRewardMainModal } from './WithdrawRewardMainModal'

export const WithdrawRewardModals = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const { signer } = useContext(SignerContext)
  const { data, status, setCurrentId, setStatus } =
    useContext(WithdrawModalContext)

  if (
    data?.type !== 'rewards' ||
    !data.capacityCommitments ||
    data.capacityCommitments.length === 0 ||
    !signer
  )
    return null

  function onClose() {
    setCurrentId('')
  }

  const { capacityCommitments, title } = data

  if (status === 'waiting') {
    return (
      <WithdrawRewardMainModal
        title={title}
        signer={signer}
        onClose={onClose}
        onChangeStatus={(status: WithdrawStatus) => setStatus(data.id, status)}
        capacityCommitments={capacityCommitments}
        onSuccess={onSuccess}
      />
    )
  }

  if (status === 'pending') {
    return (
      <TransactionWaitingModal
        onClose={onClose}
        isVisible
        description={
          capacityCommitments.length === 1
            ? `Capacity commitment ID: ${getShortAddress(capacityCommitments[0].id, 12)} capacity commitment`
            : `Withdraw rewards from ${capacityCommitments.length} capacity commitments`
        }
      />
    )
  }

  if (status === 'success') {
    return (
      <WithdrawTransactionSuccessModal
        title={
          capacityCommitments.length === 1
            ? `Yor successfully withdraw rewards for ${getShortAddress(capacityCommitments[0].id, 12)} capacity commitment`
            : `Withdraw rewards from ${capacityCommitments.length} capacity commitments`
        }
        onClose={onClose}
      />
    )
  }

  if (status === 'failed') {
    return <WithdrawTransactionFailedModal onClose={onClose} />
  }

  return null
}
