import { useController } from '@rest-hooks/react'
import { ContractTransactionResponse, JsonRpcSigner } from 'ethers'

import { StakeModal } from '../../components/Modals/StakeModal'
import { TransactionWaitingModal } from '../../components/Modals/TransactionWaitingModal'
import { getShortAddress } from '../../utils/getShortAddress'

import { stakeCollateralCapacityCommitment } from '../../store/stakeCollateralCapacityCommitment'

import TransactionFailedModal from './TransactionFailedModal'
import TransactionSuccessModal from './TransactionSuccessModal'

export type StakingStatus = 'waiting' | 'pending' | 'success' | 'failed'

function StakeModalContainer({
  id,
  onClose,
  collateral,
  duration,
  expectedAPR,
  onChangeStatus,
  signer,
}: {
  signer: JsonRpcSigner
  id: string
  collateral: bigint
  duration: number
  expectedAPR: string
  onClose: () => void
  onChangeStatus: (
    status: StakingStatus,
    payload?: { transactionHash: string },
  ) => void
}) {
  const ctrl = useController()

  async function onStake() {
    try {
      onChangeStatus('pending')
      const { transaction } = await ctrl.fetch(
        stakeCollateralCapacityCommitment,
        {
          capacityCommitmentId: id,
          signer,
        },
      )
      onChangeStatus('success', {
        transactionHash: (transaction as ContractTransactionResponse)?.hash,
      })
    } catch (e) {
      console.error(e)
      onChangeStatus('failed')
    }
  }

  return (
    <StakeModal
      id={id}
      duration={duration}
      collateral={collateral}
      expectedAPR={expectedAPR}
      isVisible
      onClose={onClose}
      onStake={onStake}
    />
  )
}

export interface Staking {
  status: StakingStatus
  id: string
  collateral: bigint
  duration: number
  expectedAPR: string
  transactionHash?: string
}

export const StakingModals = ({
  staking,
  onClose,
  onChangeStatus,
  signer,
}: {
  staking: Staking
  onClose: () => void
  onChangeStatus: (status: StakingStatus) => void
  signer: JsonRpcSigner
}) => {
  return (
    <>
      {staking?.status === 'waiting' ? (
        <StakeModalContainer
          {...staking}
          onClose={onClose}
          onChangeStatus={onChangeStatus}
          signer={signer}
        />
      ) : staking?.status === 'pending' ? (
        <TransactionWaitingModal
          onClose={onClose}
          isVisible
          description={`Capacity commitment ID: ${getShortAddress(staking.id, 12)}`}
        />
      ) : staking?.status === 'success' ? (
        <TransactionSuccessModal
          id={staking.id}
          collateral={staking.collateral}
          onClose={onClose}
          transactionHash={staking.transactionHash}
        />
      ) : (
        staking?.status === 'failed' && (
          <TransactionFailedModal onClose={onClose} />
        )
      )}
    </>
  )
}
