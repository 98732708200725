import React, { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { OrderType } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/filters'
import {
  CapacityCommitmentsStakeOrderBy,
  CapacityCommitmentsStakerFilters,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerFilters'
import { CapacityCommitmentStake } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import * as Accordion from '@radix-ui/react-accordion'
import { useLive } from '@rest-hooks/react'

import { ClientContext } from '../../components/ClientProvider'
import { Pagination } from '../../components/Pagination'
import { Space } from '../../components/Space'
import {
  TableBody,
  TableColumnTitle,
  TableColumnTitleWithSort,
  TableHeader,
  TableResponsive,
  TableResponsiveWrapper,
} from '../../components/Table'
import { usePagination } from '../../hooks'

import { getCapacityCommitmentsStake } from '../../store/getCapacityCommitmentsStake'

import { CapacityCommitmentRow } from './CapacityCommitmentRow'
import { ROW_TEMPLATE } from './styled'

type CapacityCommitmentSort = `${CapacityCommitmentsStakeOrderBy}:${OrderType}`

interface CapacityCommitmentTableProps {
  filters: CapacityCommitmentsStakerFilters
}

const CAPACITY_COMMITMENTS_PER_PAGE = 15

export const CapacityCommitmentTable: React.FC<
  CapacityCommitmentTableProps
> = ({ filters }) => {
  const [order, setOrder] = useState<CapacityCommitmentSort>('expectedAPR:desc')
  const [orderBy, orderType] = order.split(':') as [
    CapacityCommitmentsStakeOrderBy,
    OrderType,
  ]

  const { page, selectPage, limit, offset, getTotalPages } = usePagination(
    CAPACITY_COMMITMENTS_PER_PAGE,
  )

  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([])

  const client = useContext(ClientContext)
  const capacityCommitments = useLive(getCapacityCommitmentsStake, {
    filters,
    orderType,
    orderBy,
    client,
  })

  const allCapacityCommitments = capacityCommitments
  const hasNextPage =
    allCapacityCommitments && allCapacityCommitments.length > limit
  const pageCapacityCommitments =
    allCapacityCommitments &&
    allCapacityCommitments.slice(offset, offset + limit)

  const toggle = (id: string) => {
    if (isAccordionOpen.includes(id)) {
      return setIsAccordionOpen((arr) => arr.filter((v) => v !== id))
    }

    setIsAccordionOpen([...isAccordionOpen, id])
  }

  const handleSort = (
    key: CapacityCommitmentsStakeOrderBy,
    order: OrderType,
  ) => {
    setOrder(`${key}:${order}`)
  }
  return (
    <>
      <TableResponsiveWrapper>
        <TableResponsive minWidth="1200px">
          <TableHeader template={ROW_TEMPLATE}>
            <TableColumnTitle>Capacity Commitment Id</TableColumnTitle>
            <TableColumnTitle>Provider Name</TableColumnTitle>
            <TableColumnTitleWithSort
              order={orderType}
              field="duration"
              isActive={orderBy === 'duration'}
              onSort={handleSort}
              hint="How long the staked funds will be locked"
            >
              Staking Duration
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field="reqCollateral"
              isActive={orderBy === 'reqCollateral'}
              onSort={handleSort}
              justifyContent="end"
              hint="Amount of FLT tokens needed to activate this commitment"
            >
              Required Collateral
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field="reward"
              isActive={orderBy === 'reward'}
              onSort={handleSort}
              hint="The share of rewards the staker earns for Capacity Commitment, defined by provider"
            >
              Staking Reward
            </TableColumnTitleWithSort>
            <TableColumnTitleWithSort
              order={orderType}
              field="expectedAPR"
              isActive={orderBy === 'expectedAPR'}
              onSort={handleSort}
              hint="Estimated annual percentage return based on target reward per CU per epoch"
            >
              Expected Apr
            </TableColumnTitleWithSort>
          </TableHeader>
          <Accordion.Root type="multiple" value={isAccordionOpen}>
            <TableBody
              skeletonCount={CAPACITY_COMMITMENTS_PER_PAGE}
              skeletonHeight={40}
            >
              {pageCapacityCommitments?.map((capacity) => (
                <CapacityCommitmentRow
                  key={capacity.id}
                  capacityCommitment={capacity as CapacityCommitmentStake}
                  toggle={toggle}
                />
              ))}
            </TableBody>
          </Accordion.Root>
        </TableResponsive>
      </TableResponsiveWrapper>
      <Space height="32px" />
      <div style={{ alignSelf: 'flex-end' }}>
        {!capacityCommitments ? (
          <Skeleton width={200} height={34} count={1} />
        ) : (
          <Pagination
            pages={getTotalPages(allCapacityCommitments?.length)}
            page={page}
            hasNextPage={hasNextPage}
            onSelect={selectPage}
          />
        )}
      </div>
    </>
  )
}
