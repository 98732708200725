import { useAccount, useBalance } from 'wagmi'

import { Button, ButtonProps } from './Button'

export default function ContractButton({
  threshold,
  disabled,
  children,
  variant,
  ...props
}: ButtonProps & { threshold: bigint }) {
  const { address } = useAccount()
  const balance = useBalance({ address })

  const canStake = balance.data && balance.data.value > threshold
  const disabledButton = disabled || balance.isLoading || !canStake

  return (
    <Button
      {...props}
      disabled={disabledButton}
      variant={canStake ? variant : 'grey'}
    >
      {canStake ? children : 'Insufficient funds'}
    </Button>
  )
}
