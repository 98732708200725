import React from 'react'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import * as Accordion from '@radix-ui/react-accordion'
import { formatDistanceToNowStrict } from 'date-fns'

import { A } from '../../components/A'
import { CapacityStatus } from '../../components/CapacityStatus'
import { ProviderIcon } from '../../components/ProviderIcon'
import { Space } from '../../components/Space'
import { Cell, Row, RowBlock } from '../../components/Table'
import { ShrinkText, Text, UppercaseText } from '../../components/Text'
import formatPercent from '../../utils/formatPercent'
import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'

import { CapacityCommitmentRowContent } from './CapacityCommitmentRowContent'
import { AccordionContent, CaretIconStyled, RowHeader } from './styled'

export const ROW_TEMPLATE = [
  'minmax(200px, 1fr)',
  'minmax(250px, 1fr)',
  'minmax(120px, 1fr)',
  'minmax(150px, 1fr)',
  'minmax(80px, 1fr)',
  '30px',
]

interface CapacityCommitmentRowProps {
  capacityCommitment: CapacityCommitmentDashboard
  toggle: (id: string) => void
}

export const CapacityCommitmentRow: React.FC<CapacityCommitmentRowProps> = ({
  toggle,
  capacityCommitment,
}) => {
  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation()
    toggle(capacityCommitment.id)
  }

  return (
    <Accordion.Item value={capacityCommitment.id}>
      <RowBlock>
        <RowHeader asChild onClick={handleOpen}>
          <Row template={ROW_TEMPLATE}>
            <Cell>
              <A
                href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}
              >
                {getShortAddress(capacityCommitment.id, 25)}
              </A>
            </Cell>
            <Cell>
              <A
                href={`${VITE_EXPLORER_URL}/provider/${capacityCommitment.providerAddress}`}
              >
                <ProviderIcon address={capacityCommitment.providerAddress} />
              </A>
              <Space width="8px" />
              <A
                href={`${VITE_EXPLORER_URL}/provider/${capacityCommitment.providerAddress}`}
              >
                <ShrinkText size={12} color="blue">
                  <Text size={14}>{capacityCommitment.providerName}</Text>
                </ShrinkText>
              </A>
            </Cell>
            <Cell>
              <UppercaseText size={12} onlyFirst>
                {capacityCommitment.status === 'failed'
                  ? '-'
                  : capacityCommitment.finishAt &&
                    formatDistanceToNowStrict(
                      new Date(capacityCommitment.finishAt * 1000),
                      {
                        addSuffix: true,
                      },
                    )}
              </UppercaseText>
            </Cell>
            <Cell>
              <Text size={12}>
                {formatPercent(capacityCommitment.stakingReward)}%
              </Text>
            </Cell>
            <Cell>
              <CapacityStatus
                status={capacityCommitment.status}
                withdraw={capacityCommitment.collateralWithdrawn}
              />
            </Cell>
            <Cell>
              <CaretIconStyled />
            </Cell>
          </Row>
        </RowHeader>
        <AccordionContent>
          <CapacityCommitmentRowContent
            capacityCommitment={capacityCommitment}
          />
        </AccordionContent>
      </RowBlock>
    </Accordion.Item>
  )
}
