import React, { useContext } from 'react'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'

import HintedText from '../../components/HintedText'
import { SignerContext } from '../../components/SignerProvider'
import { Text } from '../../components/Text'
import TokenBalance from '../../components/TokenBalance'
import formatPercent from '../../utils/formatPercent'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'

import WithdrawCollateralButton from './Withdraw/WithdrawCollateralButton'
import WithdrawRewardButton from './Withdraw/WithdrawRewardButton'
import {
  ActionButtonContainer,
  CapacityCommitmentContentBlock,
  CapacityCommitmentInformation,
  InformationRow,
} from './styled'

export interface CapacityCommitmentRowContentProps {
  capacityCommitment: CapacityCommitmentDashboard
}

export const CapacityCommitmentRowContent: React.FC<
  CapacityCommitmentRowContentProps
> = ({ capacityCommitment }) => {
  const { signer } = useContext(SignerContext)
  const startTime = capacityCommitment.startAt
    ? formatUnixTimestamp(capacityCommitment.startAt).date
    : '-'
  const endTime = capacityCommitment.finishAt
    ? formatUnixTimestamp(capacityCommitment.finishAt).date
    : '-'

  return (
    <CapacityCommitmentContentBlock>
      <CapacityCommitmentInformation>
        <Text size={16}>Statistics</Text>
        <InformationRow>
          <HintedText
            text="Total CUs cores"
            hint="Number of Compute Units (CU) in this Capacity Commitment (CC)"
          />
          <Text size={14}>{capacityCommitment.totalCU}</Text>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Staked at"
            hint="The date when this Capacity Commitment was staked"
          />
          <Text size={14}>{startTime}</Text>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Expiration"
            hint="The period during which the CC will remain active and earn rewards, unless it fails earlier"
          />
          <Text size={14}>{endTime}</Text>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Missed proofs / Threshold"
            hint="How many times this CC’s CUs failed to submit Proofs; How many failures are allowed before it's considered failed"
          />
          <Text size={14}>
            {capacityCommitment.slashedProofs} /{' '}
            {capacityCommitment.totalPossibleProofs}
          </Text>
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Average cc apr"
            hint="The estimated yearly return rate, based on the avg of rewards earned so far"
          />
          <Text size={14}>
            {!capacityCommitment.averageAPR
              ? '—'
              : `${formatPercent(capacityCommitment.averageAPR)}%`}
          </Text>
        </InformationRow>
      </CapacityCommitmentInformation>
      <CapacityCommitmentInformation>
        <Text size={16}>Collateral</Text>
        <InformationRow>
          <HintedText
            text="Staked collateral"
            hint="The amount of FLT tokens staked for this Capacity Commitment"
          />
          <TokenBalance balance={capacityCommitment.stakedCollateral} />
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Current collateral"
            hint={
              <>
                The current value of the collateral. Equals to the staked one if
                not slashed.{' '}
                <strong>
                  May be zero for already ended CCs with collateral withdrawn
                </strong>
              </>
            }
          />
          <TokenBalance
            balance={
              capacityCommitment.collateralWithdrawn
                ? 0
                : capacityCommitment.currentCollateral
            }
          />
        </InformationRow>
        {signer && (
          <ActionButtonContainer>
            <WithdrawCollateralButton capacityCommitment={capacityCommitment} />
          </ActionButtonContainer>
        )}
      </CapacityCommitmentInformation>
      <CapacityCommitmentInformation>
        <Text size={16}>Rewards</Text>
        <InformationRow>
          <HintedText
            text="In vesting"
            hint="Earned rewards that are locked and will be vested later"
          />
          <TokenBalance balance={capacityCommitment.rewards.inVesting} />
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Available to claim"
            hint="Rewards that can be withdrawn immediately"
          />
          <TokenBalance balance={capacityCommitment.rewards.availableToClaim} />
        </InformationRow>
        <InformationRow>
          <HintedText
            text="Total claimed"
            hint="Rewards already withdrawn.
Note: If the provider initiates withdrawal first, rewards will be automatically sent to the staker's wallet"
          />
          <TokenBalance balance={capacityCommitment.rewards.claimed} />
        </InformationRow>
        {signer && (
          <ActionButtonContainer>
            <WithdrawRewardButton
              title="You are about to withdraw reward available to claim from 
              your capacity commitment"
              capacityCommitments={[capacityCommitment]}
            >
              Withdraw rewards
            </WithdrawRewardButton>
          </ActionButtonContainer>
        )}
      </CapacityCommitmentInformation>
    </CapacityCommitmentContentBlock>
  )
}
