import { useContext } from 'react'
import { CapacityCommitmentDashboard } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'
import { useController } from '@rest-hooks/react'
import { JsonRpcSigner } from 'ethers'

import { TransactionWaitingModal } from '../../../components/Modals/TransactionWaitingModal'
import { SignerContext } from '../../../components/SignerProvider'
import {
  WithdrawModalContext,
  WithdrawStatus,
} from '../../../components/WithdrawModalProvider'
import { getShortAddress } from '../../../utils/getShortAddress'

import { CapacityCommitment } from '../../../store/CapacityCommitment'
import { withdrawCollateralCapacityCommitment } from '../../../store/withdrawCollateralCapacityCommitment'
import WithdrawTransactionFailedModal from '../WithdrawTransactionFailedModal'
import WithdrawTransactionSuccessModal from '../WithdrawTransactionSuccessModal'

import { WithdrawFromCompletedCC } from './WithdrawFromCompletedCC'
import { WithdrawFromFailedCC } from './WithdrawFromFailedCC'

function WithdrawModalContainer({
  capacityCommitment,
  updateStatus,
  signer,
  onClose,
}: {
  onClose: () => void
  signer: JsonRpcSigner
  capacityCommitment: CapacityCommitmentDashboard
  updateStatus: (status: WithdrawStatus) => void
}) {
  const ctrl = useController()

  async function onWithdraw() {
    try {
      updateStatus('pending')
      await ctrl.fetch(
        withdrawCollateralCapacityCommitment,
        {
          capacityCommitment: capacityCommitment as CapacityCommitment,
          signer,
        },
        {
          collateralWithdrawn: true,
        },
      )
      updateStatus('success')
    } catch (e) {
      console.error(e)
      updateStatus('failed')
    }
  }

  if (capacityCommitment.status === 'failed') {
    return (
      <WithdrawFromFailedCC
        capacityCommitment={capacityCommitment}
        onClose={onClose}
        onWithdraw={onWithdraw}
      />
    )
  }

  return (
    <WithdrawFromCompletedCC
      capacityCommitment={capacityCommitment}
      onClose={onClose}
      onWithdraw={onWithdraw}
    />
  )
}

export const WithdrawCollateralModals = () => {
  const { signer } = useContext(SignerContext)
  const { data, status, setStatus, setCurrentId } =
    useContext(WithdrawModalContext)

  if (
    data?.type !== 'collateral' ||
    !data.capacityCommitments ||
    data.capacityCommitments.length === 0 ||
    !signer
  )
    return null

  function onClose() {
    setCurrentId('')
  }

  const { capacityCommitments } = data
  const capacityCommitment = capacityCommitments[0]

  if (status === 'waiting') {
    return (
      <WithdrawModalContainer
        signer={signer}
        onClose={onClose}
        capacityCommitment={capacityCommitment}
        updateStatus={(status: WithdrawStatus) =>
          data && setStatus(data.id, status)
        }
      />
    )
  }

  if (status === 'pending') {
    return (
      <TransactionWaitingModal
        onClose={onClose}
        isVisible
        description={`Capacity commitment ID: ${getShortAddress(capacityCommitment.id, 12)}`}
      />
    )
  }

  if (status === 'success') {
    return (
      <WithdrawTransactionSuccessModal
        title={`You have successfully withdrawn collateral for ${getShortAddress(capacityCommitment.id, 12)}`}
        onClose={onClose}
      />
    )
  }

  if (status === 'failed') {
    return <WithdrawTransactionFailedModal onClose={onClose} />
  }

  return null
}
