import { useContext, useMemo } from 'react'
import styled from '@emotion/styled'
import { RewardsStatistics } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import {
  CapacityCommitmentDashboard,
  StatusStatistics,
} from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/stakerSchemes'

import { SignerContext } from '../../components/SignerProvider'

import { STATUS_NAMES } from '../../constants/statusesMap'

import WithdrawRewardButton from './Withdraw/WithdrawRewardButton'
import { InformationBlock } from './InformationBlock'

export default function Information({
  rewardsSummary,
  statusSummary,
  capacityCommitments,
}: {
  rewardsSummary?: RewardsStatistics
  statusSummary?: StatusStatistics
  capacityCommitments?: CapacityCommitmentDashboard[]
}) {
  const { signer } = useContext(SignerContext)

  const rewardsData = useMemo(() => {
    return [
      {
        label: 'In vesting',
        value: rewardsSummary?.inVesting ?? 0,
        token: 'FLT',
        hint: 'Earned rewards that are locked and will be vested later',
      },
      {
        label: 'Available to claim',
        value: rewardsSummary?.availableToClaim ?? 0,
        token: 'FLT',
        hint: 'Rewards that can be withdrawn immediately',
      },
      {
        label: 'Claimed',
        value: rewardsSummary?.claimed ?? 0,
        token: 'FLT',
        hint: `Rewards already withdrawn.
Note: If the provider initiates withdrawal first, rewards will be automatically sent to the staker's wallet`,
      },
    ]
  }, [rewardsSummary])

  const capacityData = useMemo(() => {
    return [
      {
        label: STATUS_NAMES['waitStart'],
        value: statusSummary?.waitStart ?? 0,
        hint: 'CC is activated and awaits becoming active at the start of the next epoch',
      },
      {
        label: STATUS_NAMES['active'],
        value: statusSummary?.active ?? 0,
        hint: 'CC is operational, and its CUs are either submitting CC proofs or participating in Deals',
      },
      {
        label: STATUS_NAMES['inactive'],
        value: statusSummary?.inactive ?? 0,
        hint: `CC has successfully finished, but the staker hasn't yet withdrawn the collateral and rewards`,
      },
      {
        label: STATUS_NAMES['failed'],
        value: statusSummary?.failed ?? 0,
        hint: `CC has failed, but the staker hasn't yet withdrawn the collateral and rewards`,
      },
      {
        label: STATUS_NAMES['removed'],
        value: statusSummary?.removed ?? 0,
        hint: 'Completed CCs from which the staker has withdrawn both collateral and rewards',
      },
    ]
  }, [statusSummary])

  return (
    <Rows>
      <InformationBlock title="Capacity commitments" data={capacityData} />
      <InformationBlock
        title="Rewards"
        data={rewardsData}
        action={
          signer &&
          capacityCommitments &&
          capacityCommitments?.length > 0 &&
          rewardsSummary &&
          rewardsSummary?.availableToClaim > 0 && (
            <WithdrawRewardButton
              capacityCommitments={capacityCommitments}
              title="You are about to withdraw all rewards available to claim from all
              your capacity commitments"
            >
              Withdraw available rewards
            </WithdrawRewardButton>
          )
        }
      />
    </Rows>
  )
}

const Rows = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
`
