import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import * as Accordion from '@radix-ui/react-accordion'

import { CaretIcon } from '../../assets/icons'

import { colors } from '../../constants/colors'

export const CaretIconStyled = styled(CaretIcon)`
  transform: rotate(90deg);
  transition: transform 200ms;
`

export const RowHeaderBase = styled.div`
  width: 100%;
  padding: 8px 12px;
`

export const RowHeader = styled(Accordion.Header)`
  width: 100%;
  cursor: pointer;
  padding: 8px 12px;

  &[data-state='open'] {
    ${CaretIconStyled} {
      transform: rotate(180deg);
    }
  }
`

export const CapacityCommitmentContentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  padding: 12px;
`

export const CapacityCommitmentInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  height: 232px;
  background-color: ${colors.white};
  border-radius: 8px;
`

export const InformationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  &:first-of-type {
    margin-top: 16px;
  }
`

export const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`

export const TextWithBadge = styled(TextWithIcon)`
  gap: 8px;
`

export const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`

export const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`

export const AccordionContent = styled(Accordion.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 300ms;
  }
  &[data-state='closed'] {
    animation: ${slideUp} 300ms;
  }
`

export const ActionButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-top: auto;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  gap: 8px;
`

export const WithdrawModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const WithdrawModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const InformationRecord = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RowInfoContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 120px 160px;
  padding: 0 32px;
  gap: 50px;
`

export const RowWithdrawTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RowWithdrawTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px;
  gap: 12px;
`

export const StatusContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
